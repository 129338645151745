<template>
  <div>
    <v-card  class="transparent" outlined>
      <v-card-title> {{ message.title }} </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <p>{{ message.body }}</p>
          </v-col>
          <v-col cols="12">
            <h1 class=" font-weight-bold ">Attachments</h1>
            <v-sheet class="mx-auto rounded mt-1 transparent" max-width="">
              <v-slide-group v-model="model"  class="pa-4" mandatory show-arrows>
                <v-slide-item
                class="white "
                  v-for="(attach, n) in message.attachments"
                  :key="n"
                  v-slot="{}"
                >
                  <v-card class="mx-1" height="280" width="300">
                    <span class="text-h6">
                      {{ attach.name }}
                    </span>
                    <v-img
                      :lazy-src="$service.url + attach.path"
                      :src="$service.url + attach.path"
                      width="300"
                      height="250"
                      class="mx-auto rounded"
                    >
                    </v-img>
                  </v-card>
                </v-slide-item>
              </v-slide-group>
            </v-sheet>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
    data(){
        return {
            message:{},
            model:0,
        }
    },
    created(){
        this.$http.get('/Emails/' + this.$route.params.id).then(res => {
            this.message = res.data.result
        })
    },
    watch:{
      $route(to, from){
        to;
        from;
        this.$http.get('/Emails/' + this.$route.params.id).then(res => {
            this.message = res.data.result
        })
      }
    }
}
</script>