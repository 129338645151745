var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-list',{staticClass:"transparent",attrs:{"two-line":""}},[_vm._l((_vm.emails),function(email){return [_c('v-list-item',{key:email.id,attrs:{"to":{
          name: 'inbox-read',
          params: {
            id: email.id,
          },
        }}},[_c('v-list-item-content',[_c('div',{staticClass:"d-flex justify-space-between align-content-center"},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"font-weight-bold  ml-4"},[_c('v-badge',{attrs:{"color":email.isRead ? 'green' : 'red'}}),_c('span',{staticClass:"ml-5"},[_vm._v(_vm._s(email.title))])],1),_c('small',{staticClass:"ml-5  text-truncate mt-2",staticStyle:{"max-width":"59%"}},[_vm._v(" "+_vm._s(email.body)+" ")])]),_c('div',[_c('span',[_c('v-icon',[_vm._v("mdi-account")]),_vm._v(" "+_vm._s(email.senderName)+" ")],1),_c('v-btn',{attrs:{"to":{
                  name: 'inbox-read',
                  params: {
                    id: email.id,
                  },
                },"text":"","outlined":""}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-eye")]),_vm._v(" View ")],1)],1)])])],1),_c('v-divider',{key:"s" + email.id})]})],2),_c('v-pagination',{staticClass:"py-6",attrs:{"length":_vm.page + 1,"page-size":_vm.options.PageSize,"total-pages":_vm.totalItems,"circle":"","total-visible":7},model:{value:(_vm.options.PageIndex),callback:function ($$v) {_vm.$set(_vm.options, "PageIndex", $$v)},expression:"options.PageIndex"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }