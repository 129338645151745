<template>
  <div>
    <drawer title="Emails" :items="drawer.items" icon="email"> </drawer>
    
    <received v-if="tab == 'received'" />
    <send v-if="tab == 'send'" />
    <sended v-if="tab == 'sended'" />
    <message v-if="tab == 'read'" />
  </div>
</template>
<script>
import Drawer from "@/layouts/Drawer";
import Received from "./Received";
import Send from "./Send";
import Sended from "./Sended";
import Message from "./Message";
export default {
  components: {
    Received,
    Send,
    Sended,
    Drawer,
    Message
  },
  computed: {
    tab() {
      return this.$route.path.split("/")[2];
    },
  },
  data: () => ({
    drawer: {
      items: [
        {
          icon: "mdi-inbox-arrow-down",
          title: "Received Emails",
          route: "inbox-received",
        },
        {
          icon: "mdi-send",
          title: "Send Emails",
          route: "inbox-send",
        },
        // {
        //   icon: "mdi-alert-octagon",
        //   title: "مهم",
        //   route: "important",
        // },
      ],
    },
  }),
  watch: {},
  created() {
  },
};
</script>