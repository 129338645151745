<template>
  <div>
    <v-form
      v-model="vaild"
      @submit.prevent="postEmail"
      :disabled="loading"
      :loading="loading"
      ref="postEmail"
    >
      <v-row class="mx-2 my-2">
        <v-col cols="12">
          <label for="title">Subject</label>
          <v-text-field
            solo
            class="mt-1"
            :rules="[(v) => !!v || $t('this-field-is-requird')]"
            name="title"
            v-model="inputs.title"
            hide-details="auto"
            label="Subject"
            id="id"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <label for="body">Message</label>
          <v-textarea
            solo
            hide-details="auto"
            name="body"
            :rules="[(v) => !!v || $t('this-field-is-requird')]"
            class="mt-1"
            label="Message"
            v-model="inputs.body"
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="12">
          <v-checkbox
            hide-details="auto"
            v-model="uploadFile"
            label="Upload Attachments"
          ></v-checkbox>
          <div class="mt-3" v-if="uploadFile">
            <v-row
              dense
              align="center"
              justify="start"
              class="d-flex align-center"
              v-for="(attach, i) in inputs.attachments"
              :key="i"
            >
              <v-col cols="5">
                <v-file-input
                  v-model="inputs.attachments[i].path"
                  label="Attachment file"
                  chips
                  outlined
                  hide-details="auto"
                  :disabled="filesLoading"
                  :loading="filesLoading"
                ></v-file-input>
              </v-col>
              <v-col cols="5">
                <v-text-field
                  hide-detials="auto"
                  v-model="inputs.attachments[i].name"
                  label="Attachment name"
                  outlined
                  hide-details="auto"
                  :disabled="filesLoading"
                  :loading="filesLoading"
                ></v-text-field>
              </v-col>
              <v-col class="" cols="2">
                <div class="d-flex">
                  <v-btn
                    @click="addToList"
                    :disabled="filesLoading"
                    :loading="filesLoading"
                    color="primary"
                    text
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="i > 0"
                    @click="removeFromList(i)"
                    color="error"
                    text
                    :disabled="filesLoading"
                    :loading="filesLoading"
                  >
                    <f-icon icon="times-circle" />
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col cols="12">
          <label for="body">Users</label>
          <v-autocomplete
            class="mt-1"
            solo
            :rules="[(v) => !!v || $t('this-field-is-requird')]"
            label="Select users who receive this email"
            no-data-text="Write name to search..."
            :items="users.filter((v) => v.id != $store.state.userInfo.id)"
            item-text="fullName"
            v-model="inputs.receivers"
            multiple
            :search-input.sync="search"
            item-value="id"
            :loading="loading"
          >
            <template v-slot:selection="data">
              <v-chip
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                @click="data.select"
                @click:close="remove(data.item)"
              >
                {{ data.item.fullName }}
                <small class="mx-1">{{ data.item.userTypeName }}</small>
              </v-chip>
            </template>
            <template v-slot:item="data">
              <template>
                <v-list-item-content>
                  <v-list-item-title
                    v-html="data.item.fullName"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    v-html="data.item.userTypeName"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
          <div class="d-flex justify-end">
            <v-btn
              color="secondary"
              :disabled="loading"
              :loading="loading"
              large
              type="submit"
            >
              <v-icon class="mr-3">mdi-send</v-icon>
              Send
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      inputs: {
        body: "",
        receivers: [],
        title: "",
        attachments: [
          {
            name: "",
            path: null,
            type: "",
          },
        ],
      },
      users: [],
      search: "",
      uploadFile: false,
      vaild: false,
      filesLoading: false,
      loading: false,
    };
  },
  methods: {
    async postEmail() {
      this.loading = true;
      if (this.inputs.attachments[0].name != "") {
        this.filesLoading = true;
        var form = new FormData();
        this.inputs.attachments.forEach((attach) => {
          form.append("files", attach.path);
        });

        var img = await this.$http.post("/UploadFile/postUploadFiles", form);
        img.data.fileList.forEach((a, i) => {
          this.inputs.attachments[i].path = a.path;
          this.inputs.attachments[i].type = "attachment";
        });
      } else {
        this.inputs.attachments = [];
      }
      for (let i = 0; i < this.inputs.receivers.length; i++) {
        this.inputs.receivers[i] = {
          receiverId: this.inputs.receivers[i],
        };
      }
      this.$http
        .post("/Emails/post", this.inputs)
        .then(() => {
          this.$router
            .push({
              name: "inbox-sended",
            })
            .catch(() => {});
        })
        .catch(() => {
          this.$store.commit("UPDATE_SNACKBAR", true);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addToList() {
      this.inputs.attachments.push({
        name: "",
        path: null,
        type: "",
      });
    },
    removeFromList(index) {
      this.inputs.attachments = this.inputs.attachments.splice(index, 1);
    },
    remove(item) {
      const index = this.inputs.receivers.indexOf(item.id);
      if (index >= 0) this.inputs.receivers.splice(index, 1);
    },
  },
  created() {
    this.$http
      .get("/Users", {
        params: {
          OfficeId: this.$store.state.userInfo.office.id,
        },
      })
      .then((res) => {
        this.users = res.data.data;
        this.loading = false;
      });
  },
  watch: {
    search() {
      if (this.search !== "" && this.search !== null) {
        this.loading = true;
        this.$http
          .get("/Users?Search=" + this.search, {
            params: {
              OfficeId: this.$store.state.userInfo.office.id,
            },
          })
          .then((res) => {
            this.users = res.data.data;
            this.loading = false;
          });
      }
    },
  },
};
</script>
