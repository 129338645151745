<template>
  <div>
    <v-list class="transparent" two-line>
      <template v-for="email in emails">
        <v-list-item
          :to="{
            name: 'inbox-read',
            params: {
              id: email.id,
            },
          }"
          :key="email.id"
        >
          <v-list-item-content>
            <div class="d-flex justify-space-between align-content-center">
              <div class="d-flex flex-column">
                <div class="font-weight-bold  ml-4">
                  <v-badge :color="email.isRead ? 'green' : 'red'"></v-badge>
                  <span class="ml-5">{{ email.title }}</span>
                </div>
                <small class="ml-5  text-truncate mt-2" style="max-width: 59%">
                  {{ email.body }}
                </small>
              </div>

              <div>
                <span>
                  <v-icon>mdi-account</v-icon>
                  {{ email.senderName }}
                </span>
                <v-btn
                  :to="{
                    name: 'inbox-read',
                    params: {
                      id: email.id,
                    },
                  }"
                  text
                  outlined
                >
                  <v-icon class="mr-1">mdi-eye</v-icon>
                  View
                </v-btn>
              </div>
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-divider :key="`s` + email.id"></v-divider>
      </template>
    </v-list>
    <!-- pagination -->
    <v-pagination
      v-model="options.PageIndex"
      :length="page + 1"
      :page-size="options.PageSize"
      :total-pages="totalItems"
      circle
      :total-visible="7"
      class="py-6"
    >
    </v-pagination>
  </div>
</template>
<script>
import Pusher from "pusher-js";
export default {
  data() {
    return {
      emails: [],
      page: 0,
      totalItems: 0,
      options: {
        ReceiverId: this.$store.state.userInfo.userData.id,
        PageIndex: 1,
        PageSize: 8,
      },
    };
  },
  mounted() {
    Pusher.logToConsole = false;
    this.pusher = new Pusher("b4d809eca77d687d414a", {
      cluster: "ap2",
    });
    this.channel = this.pusher.subscribe("01emailChannelSecret");
    this.channel.bind(this.$store.state.userInfo.userData.id, (data) => {
      //
      this.emails.unshift(data);
    });
  },
  watch: {
    options: {
      handler() {
        this.getEmails();
      },
      deep: true,
    },
  },
  methods: {
    getEmails() {
      let filter =
        "?" +
        Object.entries(this.options)
          .map(([key, val]) => {
            if (val !== "" && val !== null) {
              return `${key}=${val}`;
            }
          })
          .join("&");
      this.$http.get("/Emails" + filter.replace("?&", "?")).then((res) => {
        this.emails = res.data.data;
        this.totalItems = res.data.count;
        this.page = parseInt(this.totalItems / this.options.PageSize);
      });
    },
  },
  created() {
    this.getEmails();
    // this.options.ReceiverId =
  },
};
</script>
